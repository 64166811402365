import React, { Component } from 'react';
import HubspotForm from 'react-hubspot-form'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false
    }
  }

    render() {
      const { showSuccess } = this.state;
        return (
            <React.Fragment>
                 <header id="header">
        <div className="header-overlay">
            <div className="container">
                <div data-scroll-reveal="enter top and move 50px over 1.2s" className="hexagon">
                    <i className="fa fa-flash"></i><span></span>
                </div>
                <div className="main-head">
                    <h2 data-scroll-reveal="enter left and move 50px over 1.8s">
                        An awesome and convenient care coming soon</h2>
                    <p data-scroll-reveal="enter right and move 50px over 2.0s">
                        We are building cool Telemedicine soloution for doctors and patients without any hassles. That will open up world of possibilities for you on the go!</p>
                </div>
            </div>
		</div>
    </header>
    <section id="services">
      <div className="services-overlay">
        <div className="container">
          <div className="main-head">
            <h2 data-scroll-reveal="enter top and move 50px over 1.2s">
              About Us
            </h2>
            <h5 data-scroll-reveal="enter bottom and move 50px over 1.4s">
              We connect you with the world of coveneinient care possibilities.
            </h5>
            <br />
            <p data-scroll-reveal="enter bottom and move 50px over 1.4s">
              MrVitals is a small group of dedicated people working hard around
              the table and the clock to make awesome possibilities, helping
              doctors and patients.
            </p>
          </div>
          <div id="services-blocks" className="row">
            <div
              data-scroll-reveal="enter bottom and move 50px over 1.6s"
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12 sblock"
            >
              <span className="fa fa-star-o"></span>
              <h4>
                Creative
              </h4>
              <p>
                A unique creative approach.
              </p>
            </div>
            <div
              data-scroll-reveal="enter top and move 50px over 1.7s"
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12 sblock"
            >
              <span className="fa fa-smile-o"></span>
              <h4>
                Affordable
              </h4>
              <p>
                You decide and we stay out of it
              </p>
            </div>
            <div
              data-scroll-reveal="enter bottom and move 50px over 1.8s"
              className="col-lg-4 col-md-4 col-sm-12 col-xs-12 sblock"
            >
              <span className="fa fa-eye"></span>
              <h4>
                Helathy Society
              </h4>
              <p>
                That's what we pride at - A Healthy Society!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact">
      <div className="container">
        <div className="main-head">
          <h2
            data-scroll-reveal="enter top and move 50px over 1.2s"
            className="MT40"
          >
            Contact us & start earning
          </h2>
        </div>
        <div className="row">
          <div
            data-scroll-reveal="enter left and move 50px over 1.6s"
            id="contact-area"
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            {showSuccess && (
                              <div className="alert alert-success" role="alert">
                              <p>
                              Your request has been received successfully, our
                                team will contact you shortly.
            </p>
                              </div>
                          )}
                           {!showSuccess && (
            <HubspotForm
              portalId='7822651'
              formId='c92fd3b6-ffdb-4003-8a73-c98a551f3ca6'
              onSubmit={() => {}}
              onReady={() => {}}
              onFormSubmitted={() => {
                this.setState({
                  showSuccess: true,
                });
                setTimeout(() => {
                  this.setState({
                    showSuccess: false,
                  });
                }, 2000);
              }}
              loading={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img alt="loader" className="img-responsive" src={require("../../assets/imgs/loader.gif")} /></div>}
              /> 
              )}
          </div>
          <div
            data-scroll-reveal="enter right and move 50px over 1.8s"
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <p>
              Fill out the form and our team will contact you and guide you how you can increase your appointments count (Up to 100%*) by using our cutting edge system.
            </p>
          </div>
        </div>
      </div>
    </section>
    <footer id="footer">
      <div className="container">
        <div data-scroll-reveal="enter bottom and move 50px over 1.2s">
          <p data-scroll-reveal="enter over 3.2s">
            Copyright 2020 MrVitals. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
            </React.Fragment>
        );
    }
}

export default Home;