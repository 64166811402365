import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return (
            <div style={{ minHeight: "100vh", height: "100vh", backgroundColor: "#fff", textAlign: "center", display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
                <h2 style={{ fontFamily: "Georgia, serif", color: "#4a4a4a", lineHeight: "1.5" }}>
                    Sorry, this page doesn't exist.<br />Please check the URL or go back a page.
                </h2>
                <h3 style={{ fontFamily: "Verdana, sans-serif", color: "#7d7d7d", fontWeight: "300" }}>
                    404 Error. Page Not Found.
                </h3>
            </div>
        );
    }
}

export default NotFound;