import React from 'react';
import { Link, useParams } from "react-router-dom";

export default function Instructions() {
    const {token} = useParams();
        return (
            <div>
                <section id="services">
      <div className="services-overlay">
        <div className="container">
          <div className="main-head">
            <h2>
              Instructions
            </h2>
            <h5>
                MrVitals needs access to your <b>Camera</b> and <b>Mic</b>, so doctor can perform a check-up on you.
            </h5>
            <br />
            <h5>
                See details below. The popup might appear multiple times in your browser
            </h5>
          </div>
          <div id="services-blocks" className="row">
            <div              
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
                <h4>
                Web
              </h4>
              <img width="95%" height="300px" style={{ objectFit: "contain" }} src={require("../../assets/imgs/allow-access-web.png")} alt="web instructions" />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
                <h4>
                Mobile
              </h4>
             <img width="95%" height="300px" style={{ objectFit: "contain" }} src={require("../../assets/imgs/allow-access-mobile.png")} alt="web instructions" />
            </div>
          </div>
          <div id="services-blocks" className="row">
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            >
                <Link to={`/join/${token}`} className="btn btn-lg btn-success">
                    Ok
                </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
            </div>
        );
}
