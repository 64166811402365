import React, {  useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from "moment";
import AppBar from '@material-ui/core/AppBar';
import LocalAudioLevelIndicator from './LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import { TwilioError } from 'twilio-video';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import { Appointment } from "../../state/Appointment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { token } = useParams();
  const { user, getToken, getAppointmentInfo, isFetching, setError } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if(token) {
      getAppointmentInfoLocalMethod();
    }
  }, [token]);

  const getAppointmentInfoLocalMethod = () => {
    if(token) {
      getAppointmentInfo(token).then(appointmentInfo => {
        if(appointmentInfo !== undefined) {
        let appointment: Appointment = JSON.parse(JSON.stringify(appointmentInfo));
        if(appointment.status !== "active") {
          const error = { message: "Your appointment is already completed or cancelled, please contact doctor." } as TwilioError;
          setError(error);
          return;
        }
        let startDateTime = moment(appointment.date + " " + appointment.startTime, "YYYY-MM-DD HH:mm");
        let endDateTime = moment(appointment.date + " " + appointment.endTime, "YYYY-MM-DD HH:mm");
        let currentTime = moment();
        let patientName = appointment.firstName + " " + appointment.lastName;
        let room = appointment.doctorId + ":" + appointment.firstName + ":" + appointment.lastName + ":" + appointment.date + ":" + appointment.startTime + ":" + appointment.endTime;
        if(startDateTime.valueOf() > currentTime.valueOf() && currentTime.valueOf() >= (startDateTime.valueOf() - (5 * 60000))) {
          setName(patientName);
          setRoomName(room);
          getTwilioRoomToken(patientName, room);
        } else if(startDateTime.valueOf() <= currentTime.valueOf() && endDateTime.valueOf() >= currentTime.valueOf()) {
          setName(patientName);
          setRoomName(room);
          getTwilioRoomToken(patientName, room);
        } else if(endDateTime.valueOf() < currentTime.valueOf() && currentTime.valueOf() <=  (endDateTime.valueOf() + (5 * 60000))) {
          setName(patientName);
          setRoomName(room);
          getTwilioRoomToken(patientName, room);
        } else if(endDateTime.valueOf() < currentTime.valueOf()) {
          const error = { message: "Your appointment cannot be started as the appointment time has been passed" } as TwilioError;
          setError(error);
        } else {
          const error = { message: "You cannot start your video call, you can start video call once the appointment start time is less 5 minutes" } as TwilioError;
          setError(error);
        }
      }
      });
    }
  }

  const getTwilioRoomToken = (patientName: string, room: string) => {
    getToken(patientName, room).then(twilioToken => {
      return connect(twilioToken)
    });
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {
          roomState === 'disconnected' && <button onClick={() => {
            window.location.href = "/";
          }} className="btn btn-danger">Close</button>
        }
        {roomState === 'disconnected' ? (
          (isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />
        ) : (
          <h3>{name}</h3>
        )}
        <div className={classes.rightButtonContainer}>
          <LocalAudioLevelIndicator />
          <FlipCameraButton />
          <ToggleFullscreenButton />
        </div>
      </Toolbar>
    </AppBar>
  );
}
